import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ImgApi, Folder } from "./consts";

const Player = ({ audio }) => (
  <AudioPlayer
    src={`${ImgApi}/${Folder}/${audio}`}
    style={{
      maxWidth: "287px",
      margin: "10px 0px 10px auto",
      padding: "2px 4px",
    }}
  />
);

export default Player;
