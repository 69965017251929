/**
 * Write-only the password as cookie
 */
import React, { useState } from "react";
import { setSessionPassword } from "../utils/utils";
import Layout from "../../../components/layout";

const styles = {
  wrapper: {
    width: "100%",
    marginBottom: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "200px",
    height: "28px",
    borderRadius: "2px",
  },
  button: {
    width: "200px",
    height: "36px",
    background: "#fff",
    color: "#666",
    border: "1px solid #333",
    marginTop: "16px",
    cursor: "pointer",
  },
  buttonHover: {
    background: "#fff",
    color: "#000000",
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState("");
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Layout>
      <form onSubmit={onSubmit} style={{ ...styles.wrapper }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null),
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          进 入
        </button>
      </form>
    </Layout>
  );
};

export default PasswordProtect;
