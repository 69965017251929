import React from "react";
import ImageZoom from "react-medium-image-zoom";
import { ImgApi, Folder } from "./consts";

const ImageCustom = ({ img, alt = "" }) => (
  <ImageZoom
    zoomMargin={60}
    image={{
      src: `${ImgApi}/${Folder}/${img}`,
      alt,
    }}
    zoomImage={{
      src: `${ImgApi}/${Folder}/${img}`,
      alt,
    }}
  />
);

export default ImageCustom;
